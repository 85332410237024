<template>
  <div class="rebate-commencement">
    <div class="my-container">
      <wx01></wx01>
    </div>
  </div>
</template>

<script>
import Wx01 from '@/views/Wechat/WX01'

export default {
  name: 'AntiFork',
  data () {
    return {
    }
  },
  components: {
    Wx01
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">
.rebate-commencement {
  width: calc(100% + 2rem);
  height: calc(100% + 2rem);
  margin: -1rem;
  padding: 1rem;
}
.my-container {
  magin: 0 !important;
  width: 100%;
}
</style>
